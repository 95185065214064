<template>
  <div>
    <h1 class="mt-5">{{ $t('Uživatelé') }}</h1>

    <b-table
        bordered
        class="mt-2"
        :fields="dtUsers.fields"
        hover
        :items="users_data"
        responsive
        small
        stacked="md"
    >
    </b-table>
  </div>
</template>

<script>
import { state, mutations } from '@/store/index.js'

export default {
    components: { },

    computed: {
        users_data( ) {
            return state.users_data
        }
    },

    created( ) {
        mutations.readSetting( )
    },

    data( ) {
        return {
        dtUsers: {
            fields: [
            {
                key: "user_id",
                label: this.$t("Id"),
                tdClass: ["text-left", "fw-bold"],
                thClass: "bg-light"
            },
            {
                key: "first_name",
                label: this.$t("Jméno"),
                tdClass: ["text-left", "fw-bold"],
                thClass: "bg-light"
            },
            {
                key: "surname",
                label: this.$t("Příjmení"),
                tdClass: ["text-left", "fw-bold"],
                thClass: "bg-light"
            },
            {
                key: "role_name",
                label: this.$t("Role"),
                tdClass: ["text-left", "fw-bold"],
                thClass: "bg-light"
            },
            {
                key: "username",
                label: this.$t("Uživatelské jméno"),
                tdClass: ["text-left", "fw-bold"],
                thClass: "bg-light"
            },
            {
                key: "phone",
                label: this.$t("Telefon"),
                tdClass: ["text-left", "fw-bold"],
                thClass: "bg-light"
            },
            {
                key: "email",
                label: this.$t("Email"),
                tdClass: ["text-left", "fw-bold"],
                thClass: "bg-light"
            }
            ]
        }
        }
    },

    methods: { },

    mounted( ) {
        mutations.readUsers( )
    }
}
</script>